import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var LOGIN_ERROR_CODE = {
  APP_INTERFACE_INIT_APP_CATCH: 1,
  REQUEST_HELPER_API_REQUEST_CATCH: 2,
  REQUEST_HELPER_API_REQUEST_SET_RESTART_SESSION: 3,
  REQUEST_HELPER_ALLOW_ARRAY_API_REQUEST_CATCH: 4,
  REQUEST_HELPER_ALLOW_SET_API_REQUEST_RESTART_SESSION: 5,
  LOGIN_INTERFACE_RESTART_SESSION_CATCH: 6,
  LOGIN_INTERFACE_LOGIN_CATCH: 7,
  LOGIN_INTERFACE_APPLE_LOGIN_CATCH: 8,
  LOGIN_INTERFACE_GOOGLE_LOGIN_CATCH: 9,
  LOGIN_INTERFACE_FB_LOGIN_CATCH: 10,
  LOGIN_INTERFACE_GUEST_LOGIN_CATCH: 11,
  RESTART_SESSION_LOGIN_ERROR_HANDLER: 12,
  RESTART_SESSION_LOGOUT_USER: 13,
  FACEBOOK_SDK_INIT_CATCH: 43,
  FETCH_DAILY_WHEEL_CATCH: 47,
  TC_STAR_GOOGLE_RENDER_BUTTON_ERROR: 60
};
var ODR_ERROR_CODE = {
  ODR_DOWNLOAD_RESOURCE_CATCH: 14,
  ODR_WEBVIEW_LOADING_ERROR: 15,
  ODR_GET_PATH_IS_AVAILABLE_CATCH: 16,
  ODR_SET_HIGH_PRESERVATION_PRIORITY_CATCH: 17,
  ODR_SET_LOW_PRESERVATION_PRIORITY_CATCH: 18
};
var DIVE_SDK_ERROR_CODE = {
  DIVE_REMOTE_CONFIG_FAIL: 19,
  DIVE_JSON_PARSE_FAIL: 23
};
var JSONSCHEMA_ERROR_CODE = {
  JSONSCHEMA_VALIDATION_FAIL: 20,
  JSONSCHEMA_ERRORS_SKIPED: 22
};
var SAGAS_RESPONSE_ERROR_CODE = {
  SAGAS_RESPONSE_FAIL: 21
};
var APP_INTERFACE_ERROR_CODE = {
  UPDATE_APP_EVENTS_FAIL: 24,
  GET_URLS_FAIL: 25
};
var BONUS_INTERFACE_ERROR_CODE = {
  GET_DAILY_BONUS_FAIL: 26,
  CLAIM_DAILY_BONUS_FAIL: 27,
  CLAIM_TIMED_BONUS_FAIL: 28,
  SUBMIT_BONUSABLE_LINK_FAIL: 29,
  COLLECT_WELCOME_BONUS_FAIL: 30,
  DAILY_SPIN_GET_BONUS_INFO_ERROR: 47
};
var REQUEST_HELPER_ERROR_CODE = {
  API_REQUEST_WITH_EXTRAS_FAIL: 31
};
var GAME_INTERFACE_ERROR_CODE = {
  LOBBY_OVERLAY_FETCH_GAME_ROOMS_FAIL: 32,
  INIT_GAME_FAIL: 74
};
var PURCHASE_MOBILE_FLOW_ERROR = {
  PURCHASE_ERROR_HANDLER_LISTENER: 33,
  PURCHASE_ERROR_BUY_HANDLE: 34,
  PURCHASE_ERROR_FINISH_PURCHASE: 35,
  PURCHASE_ERROR_CANCELED: 36,
  PURCHASE_CONNECTION: 37,
  PURCHASE_ITEM_OWNED: 38,
  PURCHASE_ITEM_SKU_EMPTY: 39,
  PURCHASE_RECEIPT_UNAVAILABLE: 40
};
var PROPERTIES_ERROR_CODE = {
  GET_TEXTS_FAIL: 41
};
var QUERY_RESPONSE_ERROR_CODE = {
  QUERY_RESPONSE_ERROR: 42
};
var SIGNUP_ERROR_CODE = {
  SIGNUP_ERROR: 44
};
var ACTIONS_COMANDS_ERROR_CODE = {
  ACTIONS_COMANDS_VALIDATION_ERROR: 45,
  ACTIONS_COMANDS_ASSETS_ERROR: 46
};
var REWARD_CENTER_ERROR_CODE = {
  INIT_REWARD_CENTER_REQUEST_ERROR: 49,
  GET_REWARD_DETAILS_REQUEST_ERROR: 68,
  PURCHASE_OFFER_REQUEST_ERROR: 69,
  GET_REWARD_HISTORY_REQUEST_ERROR: 70,
  GET_OFFER_HISTORY_DETAILS_REQUEST_ERROR: 71,
  SEND_OFFER_EMAIL_REQUEST_ERROR: 72
};
var TEXTS_ERROR_CODE = {
  TEXTS_ERROR: 48,
  TERMS_AND_CONDITIONS_TEXTS_ERROR: 65,
  TERMS_AND_CONDITIONS_TEXTS_BLOCKS_ERROR: 66,
  PRIVACY_POLICTY_TEXTS_ERROR: 67
};
var SHOP_INTERFACE_ERROR_CODE = {
  GET_BILLING_PROFILES_ERROR: 50,
  GET_ADDRESS_FOR_PURCHASE_ERROR: 51,
  GET_CURRENCY_PACKAGES_ERROR: 52,
  REMOVE_BILLING_PROFILE_ERROR: 53,
  FINISH_WEB_PURCHASE_ERROR: 54,
  CHECK_PROMO_CODE_ERROR: 55,
  PURCHASE_WITH_CASINO_DOLLARS_ERROR: 56,
  REFRESH_DTS_ERROR: 57,
  REFRESH_ACTIVE_CONSUMABLES_ERROR: 58,
  CLAIM_FREE_CONSUMABLE_ERROR: 59
};
var EVENTS_TUTORIAL_ERROR_CODE = {
  EVENTS_TUTORIAL_DISMISS_MODAL_ERROR: 73
};
var NOTIFICATIONS_PERMISSIONS_ERROR_CODE = {
  REQUEST_ANDROID_ALARMS_PERMISSIONS_ERROR: 75
};
var OPEN_URL_ERROR_CODE = {
  NO_URL_PRESENT_ERROR: 76,
  NOT_SUPPORTED_URL_ERROR: 77,
  OPEN_URL_ERROR: 78
};
var MISSION_ERROR_CODES = {
  MISSION_CARD_REWARD_COLLECT_ERROR: 79
};
var ASSETS_PRELOAD_ERROR_CODE = {
  ASSETS_PRELOAD_ERROR: 80
};
var LEVEL_UP_ERROR_CODE = {
  LEVEL_UP_ERROR: 81
};
var FORGOT_PASSWORD_ERROR_CODE = {
  FORGOT_PASSWORD_ERROR: 83
};
var LINK_LOYALTY_CARD_ERROR_CODE = {
  LINK_LOYALTY_CARD_ERROR: 84
};
var PLAYER_PROFILE_ERROR_CODE = {
  GET_PLAYER_PROFILE_ERROR: 82,
  UPDATE_PLAYER_PROFILE_ERROR: 85,
  GET_PLAYER_CASINO_DOLLAR_BALANCE_ERROR: 86,
  TOGGLE_SOUND_ERROR: 87,
  TOGGLE_MUSIC_ERROR: 88,
  CHANGE_PLAYER_PASSWORD_ERROR: 89,
  ACCEPT_NEW_TERMS_ERROR: 90
};
var SSO_LINKS_ERROR_CODE = {
  SSO_LINKS_ERROR_CODE: 91
};
var CUSTOMER_SUPPORT_ERROR_CODE = {
  CUSTOMER_SUPPORT_ERROR_CODE: 92
};
var RATE_US_ERROR_CODE = {
  OPEN_RATE_US_LINK_ERROR_CODE: 100
};
var ERROR_CODE = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, LOGIN_ERROR_CODE), ODR_ERROR_CODE), DIVE_SDK_ERROR_CODE), JSONSCHEMA_ERROR_CODE), SAGAS_RESPONSE_ERROR_CODE), APP_INTERFACE_ERROR_CODE), BONUS_INTERFACE_ERROR_CODE), REQUEST_HELPER_ERROR_CODE), GAME_INTERFACE_ERROR_CODE), PROPERTIES_ERROR_CODE), PURCHASE_MOBILE_FLOW_ERROR), QUERY_RESPONSE_ERROR_CODE), SIGNUP_ERROR_CODE), TEXTS_ERROR_CODE), ACTIONS_COMANDS_ERROR_CODE), SHOP_INTERFACE_ERROR_CODE), REWARD_CENTER_ERROR_CODE), EVENTS_TUTORIAL_ERROR_CODE), NOTIFICATIONS_PERMISSIONS_ERROR_CODE), OPEN_URL_ERROR_CODE), MISSION_ERROR_CODES), ASSETS_PRELOAD_ERROR_CODE), LEVEL_UP_ERROR_CODE), FORGOT_PASSWORD_ERROR_CODE), SSO_LINKS_ERROR_CODE), PLAYER_PROFILE_ERROR_CODE), LINK_LOYALTY_CARD_ERROR_CODE), CUSTOMER_SUPPORT_ERROR_CODE), RATE_US_ERROR_CODE);
export default ERROR_CODE;